
export default function Snackbar(theme) {
    return {
MuiSnackbar: {
    variants: [
        {
            props: { variant: "primary" },
            style: {
                "& .MuiSnackbarContent-root": {
                    background: theme.palette.primary.main,
                    color:theme.palette.text.primary
                },
            },
        },
        {
            props: { variant: "success" },
            style: {
                "& .MuiSnackbarContent-root": {
                    background: theme.palette.success.main,
                    color:theme.palette.text.primary
                },
            },
        },
        {
            props: { variant: "error" },
            style: {
                "& .MuiSnackbarContent-root": {
                    background: theme.palette.error.main,
                    color:theme.palette.text.primary
                },
            },
        },
    ],
}
}
}