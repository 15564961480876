import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
// import Router from './routes';
// theme
import ThemeProvider from './theme';
import { Suspense,lazy } from 'react';
import Loader from './components/loader/Loader';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';

// components
const Router = lazy(() => import('./routes'));
// ----------------------------------------------------------------------

export default function App() {
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: FontAwesome;
  }`;

  const style = document.createElement('style');
  style.type = 'text/css';

  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }
  document.head.appendChild(style);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <Suspense fallback={<Loader/>}>
           <Router />
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
