export const appConstants = {
	CREATE_APP: "CREATE_APP_CONSTANT",
    STORE_APP: "STORE_APPS_CONSTANT",
    APP_CONFIGURATION:"APP_CONFIGURATION",
    APP_BOTTOM_BAR_DATA:"APP_BOTTOM_BAR_DATA",
    APP_BOTTOM_BAR_SETTINGS:"APP_BOTTOM_BAR_SETTINGS",
    APP_LAUNCHSCREEN_DATA:"APP_LAUNCHSCREEN_DATA",
    APP_COLOR_THEME_DATA:"APP_COLOR_THEME_DATA",
    APP_ICON_DATA:"APP_ICON_DATA",
    APP_HOME_SCREEN_DATA:"APP_HOME_SCREEN_DATA",
    APP_LOGIN_SIGNUP_SCREEN_DATA:"APP_LOGIN_SIGNUP_SCREEN_DATA",
    APP_DETAIL:"APP_DETAIL",
    WEB_VIEW:"WEB_VIEW",
    TROUBLESHOOT_DATA:"TROUBLESHOOT_DATA",
    MONETIZATION_DATA:"MONETIZATION_DATA",
    PERSONAL_APPS:'PERSONAL_APPS'
};