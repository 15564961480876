import { combineReducers } from "redux";
import {authentication} from './authetication_reducer'
import { snackbarReducer } from "./snackbar_reducer";
import { appReducer } from "./app_reducers";
import { menubarReducer } from "./menubar_reducer";
import { workspaceReducer } from "./workspace_reducers";
import { teamReducer } from './team_reducers';
import { toggleDarkMode } from "./authetication_reducer";

const allReducers = combineReducers({
authentication,
snackbarReducer,
appReducer,
menubarReducer,
workspaceReducer,
teamReducer,
toggleDarkMode
});

const rootReducer = (state, action) => {
	return allReducers(state, action);
};

export default rootReducer;