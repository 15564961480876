import { alpha } from '@mui/material/styles';
import { pink as muiPink,grey } from '@mui/material/colors';
import { colord } from 'colord';
// ----------------------------------------------------------------------

// SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
// };
const PINK = {
  0: '#f9b1bc',
  100: '#f9a5b2',
  200: '#f999a7',
  300: '#f98d9d',
  400: '#f88192',
  500: '#f87588',
  600: '#f7697d',
  700: '#f75d72',
  800: '#f7576d',
  900: '#f65167',
};

const PRIMARY = {
  lighter: '#b0caf5',
  light: '#dbe1ff',
  main: '#8AB4F9',
  dark: '#99BCF4',
  darker: '#7199d9',
  contrastText: '#000000',
};

const SECONDARY = {
  lighter: '#ebbdf2',
  light: '#84A9FF',
  main: '#ce93d8',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#000000',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#0288D1',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#66bb6a',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: grey[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFA726',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: grey[800],
};

const ERROR = {
  lighter: '#f44336"',
  light: '#FFA48D',
  main: '#f44336',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};


const darkModePalette = {
  mode:'dark',
common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  pink:PINK,
  grey: grey,
  divider:grey[500],
  text: {
    primary:  colord("#ffffff").alpha(1).toHex(),
    secondary: '#A9B6D1',
    disabled: colord("#ffffff").alpha(0.8).toHex(),
  },
  background: {
    paper: "#1B2230",
    default: "#141A26",
    neutral: grey[200],
    header:'#FFFFFFB3',
    collaspableRow:colord("#000000").alpha(0.01).toHex(),
    customTableHeader:colord("#ffffff").alpha(0.08).toHex()
  },
  action: {
    active: colord("#ffffff").alpha(0.56).toHex(),
    hover: colord("#ffffff").alpha(0.08).toHex(),
    selected: colord("#ffffff").alpha(0.16).toHex(),
    disabled: colord("#ffffff").alpha(0.30).toHex(),
    focus: colord("#ffffff").alpha(0.12).toHex(),
    disabledBackground: colord("#ffffff").alpha(0.12).toHex(),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    activeRow:colord("#ffffff").alpha(0.01).toHex(),
  },
  border:{
    card:colord("#ffffff").alpha(0.12).toHex()
  }
};



export default darkModePalette;
