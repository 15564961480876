import { workspaceConstants } from "src/.constants";

const current_workspace = JSON.parse(localStorage.getItem("workspace"));
const personal_workspace = localStorage.getItem('personal_workspace') !== 'undefined' ? JSON.parse(localStorage.getItem('personal_workspace')) : null;

const initialState = {
current_workspace,
personal_workspace
};

export function workspaceReducer(state = initialState, action) {

	switch (action.type) {
        case workspaceConstants.STORE_WORKSPACE:
            return {...state,workspaces:action.data}
		case workspaceConstants.CURRENT_WORKSPACE:
				return {...state,current_workspace:action.data}
		case workspaceConstants.PERSONAL_WORKSPACE:
					return {...state,personal_workspace:action.data}
		
		
		default:
			return state;
	}
};