import { Button, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import store from "src/.helpers/store";
import Spacer from "src/components/spacer/Spacer";

let currentstate = store.getState()
let isAuthenticated = currentstate.authentication.loggedIn && currentstate.authentication.accessToken;
export default class ErrorBoundry extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }


  render() {
    if (this.state.hasError) {
      return (
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'90vh'}}>
          <Grid>
            <img src="/assets/logo.png" alt="Error" style={{width:250}}/>
          </Grid>
          <Typography variant="subtitle1" align="center" color={"error"}>
            Sorry there's a problem loading this page please try again later or you can go to {isAuthenticated ? 'Dashboard' : 'Login'} page
          </Typography>
          <Spacer space={4}></Spacer>
          <Button onClick={()=>window.location.replace(isAuthenticated ? '/my-apps' : '/login')} variant="contained">{isAuthenticated ? 'Dashboard' : 'Login'}</Button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
