import { appConstants } from "src/.constants";

const app_Configurations = JSON.parse(
  localStorage.getItem("app_configurations")
);
const app_BottomBarData = app_Configurations
  ? app_Configurations.bottom_bar.data
  : "";
const app_BottomBarSettings = app_Configurations
  ? app_Configurations.bottom_bar_setting
  : "";
const app_LaunchScreenData = app_Configurations
  ? app_Configurations.launch_screen
  : "";
const app_ColorThemeData = app_Configurations
  ? app_Configurations.color_theme
  : "";
const app_iconData = app_Configurations ? app_Configurations.app_icon_data : "";
const app_detail = app_Configurations ? app_Configurations.app_detail : "";
const web_view = app_Configurations ? app_Configurations.web_view : "";
const personal_apps =
  localStorage.getItem("personal_apps") !== "undefined"
    ? JSON.parse(localStorage.getItem("personal_apps"))
    : null;
const app_homeScreenData = app_Configurations
  ? app_Configurations.home_screen
  : "";
const app_LoginSignupData = app_Configurations
  ? app_Configurations.login_screen
  : "";

const initialState = {
  app_Configurations,
  app_BottomBarData,
  app_BottomBarSettings,
  app_LaunchScreenData,
  app_ColorThemeData,
  app_iconData,
  app_detail,
  web_view,
  troubleshoot_data: {},
  personal_apps,
  app_homeScreenData,
  app_LoginSignupData,
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case appConstants.CREATE_APP:
      return { ...state, create_app_details: action.data };

    case appConstants.STORE_APP:
      return { ...state, app_Details: action.app_details };

    case appConstants.APP_CONFIGURATION:
      return { ...state, app_Configurations: action.app_configurations };

    case appConstants.APP_BOTTOM_BAR_DATA:
      return { ...state, app_BottomBarData: action.data };

    case appConstants.APP_BOTTOM_BAR_SETTINGS:
      return {...state, app_BottomBarSettings: action.app_bottom_bar_settings };

    case appConstants.APP_LAUNCHSCREEN_DATA:
      return { ...state, app_LaunchScreenData: action.data };

    case appConstants.APP_HOME_SCREEN_DATA:
      return { ...state, app_homeScreenData: action.data };

    case appConstants.APP_LOGIN_SIGNUP_SCREEN_DATA:
      return { ...state, app_LoginSignupData: action.data };

    case appConstants.APP_COLOR_THEME_DATA:
      return { ...state, app_ColorThemeData: action.data };

    case appConstants.APP_ICON_DATA:
      return { ...state, app_iconData: action.data };

    case appConstants.APP_DETAIL:
      return { ...state, app_detail: action.data };

    case appConstants.WEB_VIEW:
      return { ...state, web_view: action.data };

    case appConstants.TROUBLESHOOT_DATA:
      return { ...state, troubleshoot_data: action.data };
	  
    case appConstants.MONETIZATION_DATA:
      return { ...state, monetization_data: action.data };
    
    case appConstants.PERSONAL_APPS:
      return { ...state, personal_apps: action.data };
    default:
      return state;
  }
}