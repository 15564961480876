import { snackbarConstants } from '../.constants';

export function snackbarReducer(state = {}, action) {
	switch (action.type) {
		case snackbarConstants.SNACKBAR_SUCCESS:
			return {
				...state,
				isSnackbarOpen: true,
				displaySnackbarMessage: action.message,
				snackbarVariant: action.variant,
			};
		case snackbarConstants.SNACKBAR_CLEAR:
			return {};
		default:
			return state;
	}
};