import {userConstants} from '../.constants'

const accessToken = localStorage.getItem("access_token");
const user = JSON.parse(localStorage.getItem("user"));
const userData = user

const initialState = user ? {
	loggedIn:true,
    accessToken,
	user,
	userData,
	twoFA:{
		isOpen: false,
		step : 1},
	DisableTwoFA:{
		isOpen: false,
		step : 1},
}: {};

let InitialThemeMode = {
	mode: localStorage.getItem("theme") ? localStorage.getItem("theme") : 'light',
};

export function authentication(state = initialState, action) {
	const accessToken = action.user ? action.user.token : undefined;
	const user = action.user ? action.user.user : undefined;
	switch (action.type) {
		case userConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: user,
				accessToken: accessToken,
			};

		// case userConstants.LOGIN_FAILURE:
		// 	return {};

		case userConstants.USER_LOGOUT:
			return {
				...state,
				loggedIn: false,
				user : null,
				accessToken: null,
			}
		case userConstants.USER:
				return {...state,userData:action.data}
		case userConstants.ENABLE_2FA:
					return {...state,twoFA:action.data,}
		case userConstants.DISABLE_2FA:
						return {...state,DisableTwoFA:action.data,}
		default:
			return state;
	}
}
export const toggleDarkMode = (
	state = InitialThemeMode,
	action
) => {
	switch (action.type) {
		case userConstants.TOGGLE_DARK_MODE:
			return action.mode;
		default:
			return state;
	}
};