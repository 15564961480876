import { Box, Grid, useTheme } from '@mui/material'
import React from 'react'
import { keyframes } from '@mui/system';

const Loader = () => {
    const theme = useTheme()
    const load = keyframes`
    0% , 5%{
        left: -60px;
        width: 30px;
      }
      15% , 20%{
        left: -60px;
        width: 90px;
      }
      30% , 35%{
        left: 0px;
        width: 30px;
      }
      45% , 50%{
        left: 0px;
        width: 90px;
      }
      60% , 65%{
        left: 60px;
        width: 30px;
      }
    
      75% , 80% {
        left: 60px;
        width: 90px;
      }
      95%, 100% {
        left: 120px;
        width: 30px;
      }
`;

  return (
    <Grid padding={10} display='flex' justifyContent={'center'} width={'100%'} height={'100vh'} alignItems='center'>
    <Box
  sx={{
    color:theme.palette.pink[800],
    position:'relative',
    left: -60,
    backgroundColor: 'currentColor',
    width: 30,
    borderRadius:'50%',
    boxShadow: '60px 0 , -60px 0 ,  120px 0',
    height: 30,
    ':after':{
        content:"''",position: 'absolute',top:0,left:-60,height:30,width:30,borderRadius:10,backgroundColor:theme.palette.pink[0],animation: `${load} 3s linear infinite alternate`
    }
  }}
/>
</Grid>
  )
}

export default Loader