import { menubarConstants } from "../.constants";

const app_Configurations = JSON.parse(localStorage.getItem("app_configurations"));
const menubarData = app_Configurations ? app_Configurations.menu.data : '' ;

const initalstate ={
menubarData
}
export function menubarReducer(state = initalstate, action) {

	switch (action.type) {
		case menubarConstants.ADD_MENU_ITEM:
			return { 
				menubarData:[...state.menubarData, action.item]
		   }
		case menubarConstants.DELETE_MENU_ITEM:
			return {menubarData : [...state.menubarData.filter((item,i) => Number(action.id) !== i)]}
		   
		case menubarConstants.SET_MENU_ITEM:
			return { 
				menubarData: action.data
		   }
		
		default:
			return state;
	}
};