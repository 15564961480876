import { teamConstants } from '../.constants';

export function teamReducer(state = {}, action) {
	switch (action.type) {
		case teamConstants.STORE_TEAM_DATA:
			return {
				...state,
				teams:action.data
			};
		default:
			return state;
	}
};